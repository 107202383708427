<template>
  <div>
    <v-row class="match-height">
      <v-col cols="12">
        <v-card>
          <v-card-title>Package Edit</v-card-title>
          <v-card-text>
            <v-form ref="form" lazy-validation class="multi-col-validation">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    :rules="[rules.required]"
                    v-model="item.price"
                    label="Cost"
                    outlined
                    dense
                    placeholder="Cost"
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-file-input
                    @change="setImg"
                    v-model="item.new_image"
                    label="Package Image"
                    outlined
                    accept="image/*"
                    dense
                  ></v-file-input>
                </v-col>

                <v-col cols="12" md="6" class="justify-center d-flex">
                  <img :src="item.image" class="preview-img" v-if="item.image" />
                </v-col>

                <v-col cols="12">
                  <v-btn :loading="process" @click="submit" color="primary"> Submit </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiMagnify, mdiTrashCanOutline, mdiPencilOutline } from '@mdi/js'
import { serialize } from 'object-to-formdata'
export default {
  name: 'UserEdit',
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiPencilOutline,
        mdiTrashCanOutline,
      },
    }
  },
  data() {
    return {
      item: {},
      process: false,
      rules: {
        required: value => !!value || 'Required.',
      },
    }
  },
  computed: {
    init() {
      return this.$store.getters['Init/init']
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      var url = 'packages/' + this.$route.params.id
      this.$api.get(url).then(response => {
        this.item = response.data

        if (this.item.image != null) {
          this.item.image = process.env.VUE_APP_MEDIA_URL + this.item.image
        }
      })
    },
    setImg() {
      if (this.item.new_image == null) {
        this.item.image = null
      } else {
        this.item.image = URL.createObjectURL(this.item.new_image)
      }
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.process = true
        this.item._method = 'PUT'
        const options = {
          indices: false,
        }
        const formData = serialize(this.item, options)
        this.$api
          .post('packages/' + this.item.id, formData, {
            'Content-Type': 'multipart/form-data',
          })
          .then(response => {
            this.$toast.info(response.message)
            this.$router.push({ name: 'PackageList' })
            this.process = false
          })
          .catch(error => {
            this.process = false
            this.$toast.error(error.response.data.errors[0])
          })
      }
    },
  },
}
</script>
